.forMobile {
    display: none;
}
.tabWrapperTitle {
    display: none;
}
@media all and (min-width: 768px) and (max-width: 1024px) {
    .header {
        .header-navigation-container-background {
            margin-right: 0 !important;
        }
    }
    .grid-row.wallet_div {
        width: auto !important;
        height: auto !important;
        .wallet-wrapper {
            width: calc(50% - 15px) !important;
        }
        .wallet {
            img {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
    .wallet-wrapper.recommended > figure > figcaption, 
    .wallet-wrapper.recommended > figure > figcaption > div {
        height: 100% !important;
    }
    #applied-jobs,
    #nfn-job-list-table,
    .plan-education-que,
    .plan-employment-que,
    .plan-goal-que,
    .cv-add-education,
    .cv-add-training,
    .local-job-list-table,
    .my-cv,
    #cvList,
    .document-table, .job-application-table,
    #applied-jobs, .job-details-table, .expression-of-interest-table, .prison-job-list-table, .useful-link
    #fav-jobs {
        overflow-x: scroll;
    }
    #cvList {
        table {
            overflow-x: scroll;
            width: 100%;
            display: block;
        }
    }
    .my-cv {
        table {
            overflow-x: scroll;
            width: 100%;
            display: block;
            .description {
                width: auto;
            }
        }
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .header {
        ul.rightNav {
            width: 53.5%; 
        }
        .navigation-item {
            padding: 0 10px;
        }
    }
    .messages-page {
        .messages-container {
            .message-list-right {
                width: 72% !important;
            }
        }
    }
    .button {
        &.button-secondary {
            &#id-submit:last-child {
                margin: 15px 0 0 0 !important;
            }
        }
    }
}
@media all and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    .header {
        ul.rightNav {
            width: 48% !important; 
        }
    }
}
@media all and (min-width: 834px) and (max-width: 1023px) and (orientation: portrait) {
    ul.rightNav.column-half {
        width: 44% !important; 
        .column-one-half{
            margin-top: 0;
        }
    }
}
@media all and (min-width: 901px) and (max-width: 1031px) and (orientation: landscape){
    .header {
        ul {
            &.column-half {
                width: 53.5%;
                height: 80px;
            }
        } 
    }
    .form-control-2-1{
        width: 35%;
    }
    .search-button{
        margin-top: 4.4em!important;
    }
}
@media all and (max-width: 900px) and (orientation: landscape) {
    .messages-page {
        margin-right: 0 !important;
        .messages-container {
            .message-list-left {
                width: 11% !important;
                .govuk-grid-row {
                    .govuk-grid-column-one-third {
                        .moj-side-navigation {
                            .moj-side-navigation__item {
                                // min-height: 0 !important;
                                .avatar-circle {
                                    margin-top: 18px !important;
                                    margin-left: 15px !important;
                                }
                                .message-item {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .message-list-right {
                width: 89% !important;
            }
            .bottom-contents {
                padding: 15px;
                .hin-message {
                    width: 100% !important;
                }
            }
        }
    }
    .upper-container {
        display: none !important;
    }
    .header {
        ul {
            &.column-half {
                width: 66%;
            }
        }
    }
    .button {
        &.button-secondary {
            &#id-submit:last-child {
                margin: 15px 0 0 0 !important;
            }
        }
    }
    .display-panel {
        right: 0 !important;
    }
}
@media all and (max-width: 767px) {
    .together-mode-enabled-header {
        border-top: 7px solid #D4351C;
    }
    .modal-dialog {
        min-width: 80vw !important;
        width: 80vw!important;
        .modal-footer {
            button {
                width: auto !important;
            }
        }
    }
    .forMobile {
        display: block !important;
        .header-navigation-container {
            padding: 0!important;
            margin-right: 0 !important;
            padding: 0 0 15px 0;
        }
    }
    .forDesktop {
        display: none !important;
    }

    .header {
        .header-container{
            min-height: 85px;
        }
        #header-navigation-container {
            ul {
                height: auto;
                overflow: auto;
                li {
                    display: inline-block;
                    width: calc(100% - 7.5px)!important;
                    height: auto;
                    padding: 7.5px 15px;
                    .navigation-item {
                        padding: 0 !important;
                        text-align: left;
                        font-family: 'helvetica_bold';
                        width: auto;
                    }
                    &.active {
                        border-bottom: 0 !important;
                    }
                    .display-panel{
                        position: relative;
                        top: 10px;
                        left: 0;
                        width: 300px;
                    }
                }
            }
        }   
    }
    .header-button-menu {
        display: inline-block !important;
        width: auto;
        padding: 0 10px;
        position: absolute;
        right: 0;
        top: 0;
        height: 39px;
        background-color: #191D35;
        color: #fff;
        .icon-menu {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            -webkit-clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
            clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
            border-width: 8.66px 5px 0 5px;
            border-top-color: inherit;
            content: "";
            margin-left: 4px;
            border-top-color: currentColor;
            &.rotateIcon {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
            }
        }
    }

    .header ul.rightNav {
        float: right;
        margin-right: 0;
        margin-top: -12px;
        height: auto;
        width: 52%;
        li {
            display: inline;
        }
        &.column-half .link2 {
            margin-right: 0;
            margin-left: 33px;
        }
        .search-icon-align {
            position: relative;
            right: auto;
            font-size: 14px;
            width: 75px;
        }
        .form-control-1-1 {
            width: 46%;
        }
        &.column-half .column-one-half {
            margin-bottom: 10px !important;
            width: 100% !important;
            position: relative;
        }
    }

    li {
        &.smDiv {
            position: relative;
            top: 0;
            .chat-container {
                .btn {
                    padding: 7.5px 15px 7.5px 0;
                }
            }  
        }
    }
    .chat-icon {
        .btn {
            .chat-text {
                font-size: 18px;
            }
        }
    }
    .plan-position {
        width: auto !important;
    }
    #assessment_container {
        #surveyElement {
            .sv_main {
                .sv_container {
                    .sv_body {
                        .sv_p_root {
                            .sv_q {
                                padding: 4px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    #surveyElement {
        .sv_main {
            .sv_container {
                .sv_body {
                    padding: 0 !important;
                    .sv_p_root {
                        .sv_q {
                            padding: 0 0 0 0.3em !important;
                            .sv_q_imagepicker_inline {
                                display: inline-block !important;
                                &.sv_q_imgsel { 
                                    label {
                                        div {
                                            width: auto !important;
                                        }
                                        input[type=radio] + div {
                                            padding: 2em !important;
                                        }
                                    } 
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .content-card.recommended > figure > figcaption > .caption-div div {
        min-height: 0 !important;
    }
    .content-card.recommended > figure > figcaption span, 
    .content-card.recommended > figure > figcaption > div span {
        width: 75% !important;
    }
    .content-card.category > figure > figcaption span, 
    .content-card.category > figure > figcaption > div span {
        font-size: 20px;
    }
    .content-card.subcategory > figure > figcaption span, 
    .content-card.subcategory > figure > figcaption > div span {
        font-size: 20px;
    }
    .alignSeeAll {
        font-size: 16px;
    }
    .content-card.course > figure > figcaption span, .content-card.course > figure > figcaption > div span {
        font-size: 16px;
    }
    .grid-row.wallet_div .wallet-wrapper.recommended > figure > figcaption > .caption1-div div {
        padding: 2%;
    }
    .grid-row.wallet_div .wallet-wrapper.recommended > figure > figcaption span, 
    .grid-row.wallet_div .wallet-wrapper.recommended > figure > figcaption > div span {
        font-size: 22px !important;
    }

    #heart-add-to-my-content,
    #heart-remove-from-my-content {
        margin: 0;
        svg {
            transform: scale(0.8);
        }
    }
    .grid-row.wallet_div {
        width: auto !important;
        height: auto !important;
        .wallet {
            img {
                width: 100% !important;
                height: 100% !important;
            }
        }
        .wallet-wrapper {
            width: 100% !important;
        }
        .wallet-wrapper.recommended > figure > figcaption, 
        .wallet-wrapper.recommended > figure > figcaption > div {
            height: 100% !important;
        }
    }
    a .job-card,
    .header .tabWrapper ul {
        margin-right: 0 !important;
    }
    #applied-jobs,
    #nfn-job-list-table,
    .plan-education-que,
    .plan-employment-que,
    .plan-goal-que,
    .cv-add-education,
    .cv-add-training,
    .local-job-list-table,
    .my-cv,
    #cvList,
    .document-table, .job-application-table,
    #applied-jobs, .job-details-table, .expression-of-interest-table, .prison-job-list-table, .useful-link
    #fav-jobs {
        overflow-x: scroll;
    }
    #cvList {
        table {
            overflow-x: scroll;
            width: 100%;
            display: block;
        }
    }
    .my-cv {
        table {
            overflow-x: scroll;
            width: 100%;
            display: block;
            .description {
                width: auto;
            }
        }
    }
    .assessment_table, .work-experience-table {
        margin-right: 0 !important;
        overflow: scroll;
    }
    #id-section-dateOfBirth,
    #id-section-jobStartDate,
    #id-section-jobEndDate,
    #id-section-courseStartDate,
    #id-section-courseEndDate,
    #id-section-endDate,
    #id-section-startDate {
        .form-control-1-16 {
            width: 5.4ex;
            margin-right: 20px;
            display: inline-block;
        }
        .form-control-1-10 {
            width: 9ex;
            display: inline-block;
        }
    }
    .details-container {
        width: 100% !important;
        .flex-row {
            display: block !important;
            .panel-label,
            .panel-content {
                display: block !important;
                width: 100% !important;
                word-break: break-word;
            }
        }
    }


    .messages-page {
        margin-right: 0 !important;
        .messages-container {
            .message-list-left {
                width: 20% !important;
                .govuk-grid-row {
                    .govuk-grid-column-one-third {
                        .moj-side-navigation {
                            .moj-side-navigation__item {
                                min-height: 0 !important;
                                .avatar-circle {
                                    margin: 7px 10px 6px 7px !important;
                                }
                                .message-item {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .message-list-right {
                width: 80% !important;
            }
            .bottom-contents {
                padding: 15px;
                .hin-message {
                    width: 100% !important;
                }
            }
        }
    } 
    .btn-align {
        padding: 4px 0 2px 0 !important;
    }
    .heading-xlarge {
        margin-bottom: 30px;
    }
    #content-items-subcategory {
        margin: 0 0 15px 0 !important;
        max-height: 400px;
        overflow: scroll;
    }
    #content-items-course {
        margin: 0;
    }
    #content-items-recommended {
        .searchDiv {
            .search-input {
                width: 97%;
            }
            .search-icon-align {
                right: 0;
            }
        }
    }
    .sv_qcbc.sv_qcbx .sv_q_checkbox .sv_q_checkbox_label .sv_q_checkbox_control_label {
        padding: 8px 0px !important;
        max-width: 80%;
        line-height: 1.25;
    }
    #surveyElement .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup .sv_q_radiogroup_label {
        max-width: 85%;
        line-height: 1.25;
    }
    .upper-container{
        .months-container{
            width: 100%;
            font-size: 12px;
        }
    }
    .planWrapper{
        .upper-container.forDesktop{
            display: block!important;
            .months-container{
                width: 100%!important;
                .month-middle{
                    span{
                        display: inline-block;
                        width: 50%;
                        text-align: center;
                    }
                }
                .month-container{
                    width: 100%!important;
                    text-align: center;
                }
            }
        }
        .pathway-container{
            height: auto!important;
            display: block!important;
            .pathway-label-container{
                width: 98%!important;
                padding: 1%;
                .pathway-label{
                    font-size: 14px;
                }
            }
        }
        .pathway-timeline{
            position: relative;
            border-left: 0!important;
            width: 100%!important;
            .timeline-decorator-container{
                width: 100%;
                display: block!important;
                position: relative!important;
                .gantt-left-month, .gantt-middle-month{
                    display: block;
                    float: left;
                    width: calc(33% - 5px);
                }
                .gantt-right-month{
                    float: right;
                }
            }
            .timeline-course-container{
                display: block!important;
                position: absolute;
                top: 0;
                .course-container{
                    margin-top: 10px;
                }
            }
        }
        .today-label-container{
            .today-label{
                margin-left: -126px!important;
            }
        }
    }
    .key-information-panel{
        .three-quarters-section{
            width: 100%!important;
            h2{
                margin-left: 0!important;
                font-size: 24px!important;
            }
        }
        .complete-button{
            margin-bottom: 15px!important;
            float: left!important;
        }
        .divider{
            margin-left: 0!important;
            margin-right: 0!important;
        }
        .date-container{
            margin-left: 0!important;
            margin-right: 0!important;
            display: block!important;
            width: 100%!important;
            span{
                display: block!important;
                font-size: 16px!important;
            }
        }
    }

    // plan timeline
    .together-mode-modal-background {
        div.modal-container {
            .together-mode-modal {
                width: 95% !important;
                .content-container {
                    max-height: 480px;
                    overflow: auto;
                }
                .button-container {
                    margin-left: 0;
                    padding: 0 10px;
                    margin: 15px 0 15px 0;
                    .ml7 {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    #id-section-help-initialPassword{
        width: 100%!important;
    } 
    .chatBadge{
        left: 165px!important;
        top: 0!important;
    }
    .heading-m{
        font-size: 18px!important;
    }
    .notice-board-icon{
        height: 18px!important;
    }
    .status-tag {
        padding: 5px 8px 2px;
    }
}

@media all and (max-width: 767px) and (orientation: portrait) {
    .button-start, 
    .button-get-started {
        background-position: 65% 50% !important;
    }
    .button {
        &#id-forgot-password-button,
        &#id-save-and-come-back-later,
        &#id-save-and-continue,
        &.change-password-link,
        &#cv-summary-button,
        &#return-button,
        &#continue-button,
        &#id-submit,
        &#id-send,
        &#id-cancel,
        &#id-done,
        &#button-new-chat,
        &#button-cancel,
        &#button-send,
        &#save_draft_user_button,
        &#save_submit_user_button {
            margin: 0 0 15px 0 !important;
        }
    }
    .header ul.rightNav {
        float: none;
        margin: auto;
        width: auto;
        li {
            display: inline;
        }
        &.column-half .link2 {
            margin-right: 0;
            margin-left: 33px;
        }
        .search-icon-align {
            right: auto;
            width: 60px;
        }
        .form-control-1-1 {
            width: 45%;
        }
        &.column-half .column-one-half {
            margin-bottom: 10px !important;
        }
    }
    .header-button-menu {
        top: 1.05em;
        &:active,
        &:focus {
            top: 1em !important;
            background-color: #191D35 !important;
            color: #fff;
        }
    }
    button.change-password-link {
        padding: 10px 0 !important;
        width: 100%;
    }
    #button-add-to-plan {
        width: auto !important;
        margin: 0 !important;
    }
    .assessment_table,
    .tabWrapper .tabs-panel {
        padding: 25px 10px !important;
    }
    .messages-page .messages-container .bottom-contents .button-alignment {
        float: none;
    }
    .assessment-data-keywordList {
        li {
            a {
                float: none !important;
                margin-top: 20px !important;
                display: inline-block;
                width: 100%;
            }
        }
    }
    .tabWrapper {
        margin-top: 2em !important;
        ul {
            border-bottom: 0  !important;
            padding: 10px 15px 0 15px !important;
            li {
                text-align: left !important;
                background-color: transparent !important;
                padding: 5px 0 5px 15px !important;
                &::before {
                    color: #0b0c0c;
                    content: "\2014 ";
                    margin-left: 0px;
                    padding-right: 5px;
                }
                a {
                    float: none !important;
                    text-decoration: underline !important;
                    color: #1d70b8 !important;
                    font-size: 16px!important;
                    &:visited {
                        color: #4c2c92 !important;
                    }
                    &:active {
                        color: #1d70b8 !important;
                    }
                    &:focus {
                        background-color: transparent !important;
                        outline: 3px solid transparent !important;
                        box-shadow: none !important;
                        color: #1d70b8 !important;
                    }
                    
                }
            }
        }
    }
    .header {
        .tabWrapper {
            ul {
                li {
                    border-bottom: 0 !important;
                    padding: 5px 0 5px 15px !important;
                    margin: 0 !important;
                    &.active {
                        padding: 5px 0 5px 15px !important;
                    }
                    &:focus {
                        a {
                            background-color: transparent !important;
                            outline: 3px solid transparent !important;
                            box-shadow: none !important;
                            color: #1d70b8 !important;
                        }
                    }
                    a {
                        color: #1d70b8 !important;
                        &:visited {
                            color: #4c2c92 !important;
                        }
                        &:active {
                            color: #1d70b8 !important;
                        }
                        &:focus,
                        &.active, &:active {
                            background-color: transparent !important;
                            outline: 3px solid transparent !important;
                            box-shadow: none !important;
                            color: #1d70b8 !important;
                        }
                    }
                }
            }
        }
    }
    .tabWrapperTitle {
        display: block;
    }
}

@media all and (max-width: 767px) and (orientation: landscape) {
    .messages-page {
        .messages-container {
            .message-list-left {
                width: 11% !important;
                .govuk-grid-row {
                    .govuk-grid-column-one-third {
                        .moj-side-navigation {
                            .moj-side-navigation__item {
                                min-height: 72px !important;
                                .avatar-circle {
                                    margin-top: 18px !important;
                                    margin-left: 20% !important;
                                }
                                .message-item {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .message-list-right {
                width: 89% !important;
            }
        }
    }

    .together-mode-modal-background {
        div.modal-container {
            .together-mode-modal {
                .content-container {
                    max-height: 300px;
                    overflow: auto;
                }
                .button-container {
                    padding: 0 6%;
                    .ml7 {
                        margin-left: -7.5px;
                    }
                }
            }
        }
    }
    .result-container{
        .column-half{
            padding: 0!important;
        }
    } 
    .induction-questions-list{
        #planPathwayDescription {
            .selectOptions{
                button.column-fifth{
                    width: calc(50% - 10px)!important;
                    vertical-align: top;
                }
            }
        }
    }
}

@media all and (max-width: 835px){
    .header{
        .header-container{
            min-height: 80px;
        }
        .enter-together-mode-link, .exit-together-mode-container{
            top: 55px !important;
            left: 0 !important;
            margin-left: 0!important;
        }
        ul.rightNav.column-half {
            width: 52%; 
            float: right;
            .column-one-half{
                margin-bottom: 0!important;
                .header-button-menu{
                    top: 0!important;
                }
            }
        }
        .navigation-item {
            padding: 0 10px;
        }
    }
}

@media all and (max-width: 835px) {
    .errorMessagesdiv{
        p{
            font-size: 18px!important;
        }
    }
    .errorMessagesdiv {
        input[type=checkbox] { 
            + .errorMessages {
                div{
                    font-size: 16px!important;
                }   
            }
        }
    }
    p{
        font-size: 16px!important;
    }
    .course-title{
        font-size: 24px;
    }
    .heading-xlarge{
        font-size: 32px!important;
        margin-bottom: 30px;
    }
    .heading-large, h2.linkTab{
        font-size: 24px!important;
        margin-bottom: 20px!important;
    }
    .heading-medium, .goals-alert-heading, .induction-entry-heading{
        font-size: 18px!important;
        margin-bottom: 15px;
    }
    .induction-history-inset{
        margin-left: 0!important;
    }
    .button-link, .button-secondary{
        margin-bottom: 15px!important;
    }
    .question{
        font-size: 18px!important;
        line-height: 1.1111!important;
    }
    .radio-wrapper {
        label{
            font-size: 16px!important;
        }
    }
    .profileWrapper{
        margin-right: 0!important;
    }
    .summary-list-key{
        width: 42%;
        font-size: 16px!important;
    }
    .change-link{
        font-size: 16px;
    }
    .summary-list-actions{
        width: 110px!important;
        text-align: left!important;
        .accordion-icon{
            position: absolute;
            right: 0;
            top: 15px;
        }
    }
    .category-card-container{
        float: left;
        width: 210px!important;
    }
    .summary-list-value{
        word-break: break-word;
    }
    .form-control-2-1{
        width: 35%;
    }
    .search-button{
        margin-top: 2.9em!important;
    }
    .create-plan{
        position: relative!important;
    }
}
@media all and (max-width: 568px) {
    .header{
        .header-container{
        ul.rightNav.column-half {
                width: 60%;
            }
        }
    }
}
@media all and (max-width: 480px) {
    .header{
        .header-container{
            ul.rightNav.column-half {
                width: 100%;
                float: none;
                .form-control-1-1{
                    width: 47%;
                }
                .column-one-half{
                    margin-top: 40px;
                }
            }
            .header-logo{
                width: calc(60% - 15px);
            }
        }
    }
    .induction-questions-list{
        #planPathwayDescription {
            .selectOptions{
                button.column-fifth{
                    width: calc(100% - 10px)!important;
                    vertical-align: top;
                }
            }
        }
    }
    .category-card-container{
        width: 100%!important;
        height: 200px!important;
    }
    .form-control-2-1{
        width: 100%;
    }
    .search-button{
        margin-top: 1.4em!important;
    }
    .mar-right15{
        margin-right: 0!important;
    }
    .summary-list-actions{
        width: 60px!important;
        text-align: left!important;
        .accordion-icon{
            top: 45px;
        }
    }
    .actions-container{
        padding: 0 15px;
        width: calc(100% - 30px)!important;
        .button{
            width: 100%!important;
            margin-right: 0!important;
            margin-bottom: 15px!important;
        }
    }
    .ngx-pagination{
        a{
            padding: 7.5px 5px!important;
        }
    }
}
//this 1 change under vc-26355
@media screen and (max-width:992px){
    .header-button-menu {
        font-size: 14px;
        margin-right: 0px !important;
    }
}