@use 'sass:math';
main {
  display: block;
  background: $white;
  margin: 0;
  padding: math.div($gutter , 2) 0;

  min-height: 70vh;

  @include media(tablet) {
    padding: 15px 0;
    min-height: 85vh;
  }
}

.breadcrumbs {
  display: none;
  color: $black;

  @include media(tablet) {
    display: block;
    margin: 0;
    margin-top: 0;
    margin-bottom: -$gutter * 0.67;
    font-size: 14px;

    .breadcrumbs-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-link {
      text-decoration: none;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-list-item {
      display: inline-block;
      position: relative;
      margin-left: math.div($gutter , 3);
      padding-left: math.div($gutter , 4);
      margin-bottom: math.div($gutter , 6);

      &:before {
        content: "/";
        color: $black;
        display: block;
        position: absolute;
        top: 3px;
        left: -4px;
        margin: auto 0;
      }

      &:first-child {
        margin-left: 0;
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }
}
.modal{
  display: block;
  height: 100vh;
  background: rgba(0,0,0, 0.7);
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  overflow: hidden;
}
.modal-dialog{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border: 4px solid #000;
  min-width: 400px;
  button{
    margin-left: 0px;
  }
  .heading-large{
    margin-top: 0;
  }
  p {
    font-size: 19px;
  }
}
.sign-up-page{
  .error-summary{
      margin-top: 0;
  }
}
#id-section-help-initialPassword {
  width: 50%;
}
#content-activity-popup{
  .form-group{
    .form-control-1-16{
      label{
        font-size: 19px;
    }
    }
    .form-control-1-10{
      label{
        font-size: 19px;
      }
    }
    .form-label-b19{
      font-size: 24px;
      font-family: 'helvetica_bold';
    }
  }
  #id-endDate{
    .form-group{
      margin-bottom: 5px;
    }
  }
}
#myCvUpload, #generate_cv {
  .inlineRadioButtons {
    display: inherit;
  }
}

#planPathwayDescription{
  .selectOptions{
    button.column-fifth{
      height: 5em!important;
    }
  }
} 
.hiddenForAT {
  visibility: hidden;
}